import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { MARKS } from "@contentful/rich-text-types"
import Layout from '../components/layout'
import Head from '../components/head'

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      
      allContentfulAbout {
        edges {
          node {
            text {
              json
            }  
          }
        }
      }

      allContentfulAboutImages {
        edges {
          node {
            name
            image {
              file {
                url
              }
            }
          }
        }
      }
     
    }
  `)

  const Bold = ({ children }) => <p className="bold">{children}</p>
  const Italic= ({ children }) => <p className="italic">{children}</p>
  
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderMark: {
      [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
    },  
  }

  return (
    <Layout>
      	<img id="portrait_2_2" src={data.allContentfulAboutImages.edges[4].node.image.file.url} alt="portrait 2 2">
        </img>
        <div id="About_me">
          <span>About me</span>
        </div>
        <p></p>
        <div id="My_name_is__Botond_Gagyi_I_con_2">
        <span>I am a painter of Transylvanian origin, born in Târgu-Mureș (Romania) and currently  living and working in Cluj-Napoca where I studied Fine Arts at the University of Art and Design. There I was first awarded the degree of Bachelor of Arts, 2015, followed by Master of Arts, 2017, both within the School of Painting. Presently I am attending the same university where I am studying for a doctorate. As a result of exhibiting in several group exhibitions, not only in my own country, Romania, but also abroad, I have had the opportunity of showcasing my work in a number of solo exhibitions.  A winner of a series of exhibitions organised in 2017, I was invited to exhibit my paintings in the Trade Fair Palace of the National Gallery Prague in the Czech Republic. This event, in the framework of the StartPoint Prize 2017, is designed specifically  for European art graduates. My work is held in private collections within Europe and beyond.</span>
</div>
        <div id="About_my_art">
          <span>About my art</span>
        </div>
        <p></p>
        <div id="The_process_of_painting_is_set">
        <span>Most often the process of painting is fired by a micro story reduced to the level of everyday life, a tale of an average person who I may have happened upon on the internet. In consequence of image-making procedures, processes that are simultaneously constructive and deconstructive, the found and transformed digital image becomes alienated from its original content, it springs into its own existence to be filled with new content, to become part of the emerging visual structure. The tension between the way of painting and the inwrought bodies (micro events taken place by figures) forms an indispensable part of my paintings.</span>
	      </div>
      	<img id="Showtime__50_x_40_cm__oil_on_c_2" src={data.allContentfulAboutImages.edges[2].node.image.file.url} alt="Showtime__50_x_40_cm"></img>
        <div id="Expressions">
		      <span>Expressions</span>
	      </div>
        <p></p>
        <div id="The_body_became_the_expression">
          <span>The body became the expression of physical and emotional human experience. In this way, my paintings are not about a false representation of reality, but rather the concealing of the notion that reality is no longer real.<br/>At the same time it is important to keep in mind that these illusionary worlds have their bases in reality, seem inexhaustibly rich, but in fact they represent a world lacking space and dimensions. By the making of the Monument for Future, my recent series, I try to aim for the problematization of this particular abstraction. In this way, the oil paintings figures, which are taken out their context, by the fact that they are just seemingly together, refer exactly to these artificial relationships and their abstract nature.<br/>The frequent loose gesture maybe comprises in itself the pulsing life’s illusion, while we see that we are just simulacrum witnesses of life. This is the story that I try to compress into every one of my paintings: the excitement of life. The experience is born between the omissions, repainting, erasures and piling up of paint.</span>
        </div>
      	<img id="VRTW-003" src={data.allContentfulAboutImages.edges[3].node.image.file.url} alt="VRTW"></img>
        <Head title="About"/>
        <div id="Biography">Biography</div>
        <div id="paragraphsAbout">
          {data.allContentfulAbout.edges.map((edge) =>{
            return (
              <>{documentToReactComponents(edge.node.text.json,options)}</>
            )
          })}
        </div>
    </Layout>    
  )
}

export default AboutPage